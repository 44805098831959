import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const ReferralSection1Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  margin-bottom: 30px;
  @media(max-width:1199px){
    margin-bottom: 0px;
  }
`
const ReferralSubtitle = styled.div`
  font: 28x/32px Abril Fatface;
  font-weight: bold;
  margin-bottom:5px;
  @media(max-width:1199px){
    font: 22px/24px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:3px;
  }
`

const ReferralSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-top:61px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`

export default function ReferralSection1(props) {
  return (
  <ReferralSection1Background backgroundColor={props.Background} color={props.TextColor} >
      <img alt="WomenWorks - masterclass videos on demand mentors" src="/page-masterclass-video-on-demand-image1.png" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:145px;
            max-height: 515px;
            max-width: calc(728/1440*100vw);
            object-fit: contain;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="6" >
                <ReferralSubtitle>WomenWorks Network Exclusive: </ReferralSubtitle>
                <ReferralSection1Title>
                  MasterClass Video On Demand
                </ReferralSection1Title>
              </Col>
              <Col xs="12" lg="4">
                <img alt="WomenWorks - masterclass videos on demand mentors" src="/page-masterclass-video-on-demand-image1.png" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: contain;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      width: 516px;
                      height: 500px;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="6">
                <p css={css`margin-bottom:24px;min-height:54px;max-height:216px;max-width:505px;`}>
                  As a women-first growth platform, our power lies in the community. So, we’re rewarding a free MasterClass video for every 2 women you bring to the network <span role="img" aria-label="sparkling emoji">✨</span> The first 5 videos you can unlock are from our Personal & Career Growth Series.
                </p>
              </Col>
            </Row>
            <Row css={css`
              @media(max-width:991px){
                display:flex;
                justify-content: center;
                }`}>
              <Col xs="auto" lg="2" md="3" css={css`
              @media(max-width:1199px){
                margin-right:20px;
                }
              @media(max-width:768px){
                display:flex;justify-content:center;margin-right:0px;
                }
              `}>
              <OutboundLink href="https://apps.apple.com/us/app/womenworks/id1538858709" target="_blank" rel="noreferrer">
                <img alt="WomenWorks - WomenWorks App Questionnaire" src="/appstore-badge.png" 
                  css={css`
                    width:178px;
                    height:57px;
                    @media(max-width:768px){
                      margin-bottom: 20px;
                    }
                  `}
                />
                </OutboundLink>
              </Col>
              <Col xs="auto" lg="2" md="3">
                <OutboundLink href="https://play.google.com/store/apps/details?id=io.womenworks.app" target="_blank" rel="noreferrer" css={css`@media(max-width:768px){display:flex;justify-content:center;}`}>
                  <img alt="WomenWorks - WomenWorks App Questionnaire" src="/google-play-badge.png" css={css`width:178px;height:57px;`}/>
                </OutboundLink>
              </Col>
              <Col lg="8" md="6" css={css`
              margin-top:36px;
              @media(max-width:991px){
                display:none;
              }
              `}></Col>
            </Row>
          </SectionItem>
      </Container>
  </ReferralSection1Background>
  )
}

