import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/masterclass-video-on-demand-section1";
import Section3 from "../sections/masterclass-video-on-demand-section3";
import Section5 from "../sections/masterclass-video-on-demand-section5";
import SEO from "../components/seo";
import Section6 from "../sections/app-coming-soon-section3.js";

export default function ThankYou() {
  return (
            <Layout 
                LogoColor={props => props.theme.colors.secondary_green} 
                HeaderMenuColor={props => props.theme.colors.gray_dark} 
                firstSectionBGColor={props => props.theme.colors.white}

                //button
                HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
                HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
                HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
                
                HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
                HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
                HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

                HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
                HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
                HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
            >
          <SEO title={"Masterclass Video on Demand"} description={"Invite 2 friends and unlock your free Masterclass!"} /> 
          <Section1 ></Section1>
          <Section5></Section5>
          <Section3></Section3>
          <Section6></Section6>
      </Layout>
  )
}
