import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "@emotion/styled";
import Container from "react-bootstrap/Container";
import Profile from "./index-section7-multiple-use.js";

const ReferralSection3Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary_violet};
  font: 20px/28px Roboto;
  @media(max-width:767px) {
    min-height: 0;
    overflow:hidden;
  }
  @media(min-width:1024px) {
    margin-top:85px;
  }
`

const mentors = [
  { 
    name: "Rini Haerinnisya",
    title: "Executive and Wellbeing Certified Coach",
    goal: "Why Am I Here?",
    description: "Rini is an Associate Certified Coach licensed by the International Coach Federation. For the past 5 years, Rini has been assisting professionals and business owners who seek to redefine their life purpose. Rini previously managed marketing communications for 15 years, in companies such as Samsung and Danone.",
    subtitle: "Menemukan kembali motivasi untuk mengembangkan diri (self-growth).",
    check1: "Merefleksikan nilai-nilai dan prioritas hidup kamu",
    check2: "Menciptakan support system yang baik",
    check3: "Menjadi mandiri secara emosional, profesional, dan finansial",
    photo: "rini",
    profilePicture: "/page-masterclass-section4-img4.jpeg",
    videoPlaceholder: "/masterclass-video-on-demand-placeholder/rini-haerinnisya.jpg",
    videolink: "https://youtube.com/embed/i4jTPmJl35Y",
  },
  { 
    name: "Zilvia Iskandar",
    title: "News Anchor at Metro TV, Exclusive WomenWorks Mentor",
    goal: "Public Speaking and Communication",
    description: "Zilvia is an award-winning news anchor and experienced MC, moderator, and public speaking trainer. Kicking off her love for journalism and public speaking from newscasting competitions, she has since enjoyed an 8-year long career as a news anchor at Metro TV." ,
    subtitle: "Public Speaking and Communication",
    check1: "Belajar berbagai tipe public speaking di tempat kerja",
    check2: "Belajar mengatasi tantangan dan masalah",
    check3: "Belajar digital storytelling yang baik",
    photo: "zilvia",
    profilePicture: "/page-masterclass-video-on-demand-zilvia.jpg",
    videoPlaceholder: "/masterclass-video-on-demand-placeholder/zilvia-iskandar.jpg",
    videolink: "https://youtube.com/embed/jIdcSFT_H44",
  },
  { 
    name: "Windy Natriavi",
    title: "Co-Founder and CPO at AwanTunai Co-Founder and Mentor at WomenWorks",
    goal: "Giving and Receiving Feedback",
    description: "Windy is the Chief Product Officer and a co-founder of AwanTunai, a fintech company which provides funding for micro-enterprises. Her work with AwanTunai led to her being inducted into Forbes Asia’s 30 Under 30 list in 2019." ,
    subtitle: "Memberi Kritik dan Saran? Siapa takut!",
    check1: "Cara menggunakan kritik dan saran sebagai sarana perkembangan diri dan mengatasi konflik",
    check2: "Strategi memberi kritik dan saran yang kritis dan membangun",
    check3: "Senjata jitu untuk mengatasi konflik di tempat kerja",
    photo: "windy",
    profilePicture: "/page-masterclass-video-on-demand-windy.jpg",
    videoPlaceholder: "/masterclass-video-on-demand-placeholder/windy-natriavi.jpg",
    videolink: "https://youtube.com/embed/eSrxn_dioos",
  },
  {
    name: "Monita Moerdani",
    goal: "Building a Brand Strategy to Drive Growth",
    title: "Chief Marketing Officer at DANA Mentor at WomenWorks",
    description: "Monita is the Chief Marketing Officer at DANA. Prior to this role, she was the Senior Vice President of Transport Marketing at GOJEK and was in charge of marketing GOJEK’s transport services and building a trustworthy brand image through numerous campaigns." ,
    subtitle: "Belajar membangun bisnis dengan strategi merek yang efektif.",
    check1: "Pemahaman menyeluruh mengenai pembangunan merek",
    check2: "Bagaimana strategi sebuah merek mendorong pertumbuhan di seluruh siklus hidup produk tersebut",
    check3: "Pengetahuan dasar mengenai eksekusi strategi sebuah merek secara efektif",
    photo: "monita",
    profilePicture: "/page-masterclass-section4-img3.jpg",
    videoPlaceholder: "/masterclass-video-on-demand-placeholder/monita-moerdani.jpg",
    videolink: "https://youtube.com/embed/DgLsnQPQILE",
  },
  {
    name: "Shabrina Koeswologito",
    goal: "Enhance Your Career through Social Media",
    title: "Digital Specialist Manager at Mindshare Mentor at WomenWorks",
    description: "Shabrina is a Digital Specialist Manager at advertising firm Mindshare Indonesia. She has worked in New York City and Jakarta as a Community Manager in Zomato, Sales Strategy Associate in Yahoo! Indonesia, and Business Development Specialist at Monaghan McGuire Creative Collective." ,
    subtitle: "Gunakan media sosial untuk karir dan bisnis secara efektif!",
    check1: "Pemahaman tentang pentingnya media sosial untuk karir",
    check2: "Membedakan pesan dan ajakan bertindak (call-to-action) di setiap platform media sosial",
    check3: "Menemukan dan membangun komunitas di media sosial",
    photo: "shabrina",
    profilePicture: "/page-masterclass-section4-img5.jpg",
    videoPlaceholder: "/masterclass-video-on-demand-placeholder/shabrina-koeswologito.jpg",
    videolink: "https://youtube.com/embed/wXlhQKE8Bso",
  },
];

export default function ReferralSection3(props) {
  return (
  <ReferralSection3Background>
      <Container>
          <Profile
          Title = "Personal & Career Growth Series"
          TitleTextAlign = "center"
          Names = {mentors}
        ></Profile>
      </Container>
  </ReferralSection3Background>
  )
}